<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'BackOffice': 'Escritório Virtual',
      'Dashboard': 'Painel',
      'Reports': 'Relatórios',
      'Ecommerce': 'Loja Virtual',
      'Orders': 'Pedidos',
      'Inventory': 'Estoque',
      'Products': 'Produtos',
      'Restock': 'Reposição',
      'Wallet': 'Carteira',
      'Extract': 'Extrato',
      'Balance': 'Saldo',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
    },
    es: {
      'BackOffice': 'Escritório Virtual',
      'Dashboard': 'Painel',
      'Reports': 'Relatórios',
      'Ecommerce': 'Loja Virtual',
      'Orders': 'Pedidos',
      'Inventory': 'Estoque',
      'Products': 'Produtos',
      'Restock': 'Reposição',
      'Wallet': 'Carteira',
      'Extract': 'Extrato',
      'Balance': 'Saldo',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-info rounded mb-2">
        <router-link tag="a" to="/" class="side-nav-link-ref border-0 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">Escritório Virtual</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/account" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M18 2H6c-1.103 0-2 .897-2 2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm0 18H6V4h12v16z"></path>
            <path d="M8 6h3v2H8zm5 0h3v2h-3zm-5 4h3v2H8zm5 .031h3V12h-3zM8 14h3v2H8zm5 0h3v2h-3z"></path>
          </svg>
          <span class="notranslate">Meu PA</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path>
            <circle cx="10.5" cy="19.5" r="1.5"></circle>
            <circle cx="17.5" cy="19.5" r="1.5"></circle>
          </svg>
          <span class="notranslate">Loja Virtual</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/franchise/orders" class="side-nav-link-ref">
              <span class="notranslate">Pedidos</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/franchise/store/cart" class="side-nav-link-ref">
              <span class="notranslate">Lançar</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z">
            </path>
          </svg>
          <span class="notranslate">Estoque</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/franchise/inventory" class="side-nav-link-ref">
              <span class="notranslate">Produtos</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/franchise/inventory/orders" class="side-nav-link-ref">
              <span class="notranslate">Pedidos</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/franchise/inventory/restock" class="side-nav-link-ref">
              <span class="notranslate">Reposição</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M16 12h2v4h-2z"></path>
            <path
              d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM5 5h13v2H5a1.001 1.001 0 0 1 0-2zm15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15v10z">
            </path>
          </svg>
          <span class="notranslate">Financeiro</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/franchise/wallet/extract" class="side-nav-link-ref">
              <span class="notranslate">Extrato</span>
            </router-link>
          </li>
          <li class="d-none">
            <router-link tag="a" to="/franchise/wallet/transfer" class="side-nav-link-ref">
              <span class="notranslate">Transferir</span>
            </router-link>
          </li>
          <li class="d-none">
            <router-link tag="a" to="/franchise/wallet/withdrawal" class="side-nav-link-ref">
              <span class="notranslate">Sacar</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/franchise/wallet/withdrawal/history" class="side-nav-link-ref">
              <span class="notranslate">Histórico de Saque</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>